import axios from 'axios'

export default {
  state: {
    allUsers: [],
    settings_cal: [],
    settings_crm: [],
  },
  mutations: {
    SET_ALL_USERS(state, users) {
      state.allUsers = users
    },
    SET_SETTINGS(state, settings) {
      state.settings_cal = settings.userSettings['WEB_cal']
      state.settings_crm = settings.userSettings['WEB_crm']
    },
    RESET_SETTINGS(state) {
      state.settings_cal = []
      state.settings_crm = []
    },
  },
  actions: {
    async loadAllUsers({ commit }) {
      return axios.get(`/v1/settings/users`).then((data) => {
        commit('SET_ALL_USERS', data.data)
      })
    },
    async loadSettings({ commit }, { user, app }) {
      return axios
        .get(`/v1/settings/user/${user.userId}/${app}`)
        .then((data) => {
          commit('SET_SETTINGS', data.data)
        })
    },
    async resetUserSettings({ commit }) {
      commit('RESET_SETTINGS')
    },
  },
}
