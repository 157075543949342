<template>
  <v-autocomplete
    v-model="document.customerId"
    :items="searchOutputs"
    item-value="id"
    item-text="name"
    no-filter
    :search-input.sync="searchInputSync"
    :loading="searchOutputSearchLoading"
    loading-text="Globale Suche läuft"
    :no-data-text="getCorrectNoDataText()"
    label="LBA durchsuchen (mind. 3 Zeichen)"
    prepend-inner-icon="mdi-magnify"
    outlined
    dense
  >
    <template #item="{ item }">
      <v-list-item @click="openSearchItem(item)">
        <v-avatar v-if="item.type == 'ADDRESS'" size="36" class="primary">
          <v-icon color="white">mdi-account-outline</v-icon>
        </v-avatar>
        <v-avatar v-if="item.type == 'ARTICLE'" size="36" class="primary">
          <v-icon color="white">mdi-archive-outline</v-icon>
        </v-avatar>
        <v-avatar
          v-if="item.type == 'DOCUMENT'"
          size="36"
          label
          class="primary"
        >
          <v-icon color="white">mdi-file-document-outline</v-icon>
        </v-avatar>
        <v-list-item-content class="ml-4">
          <v-list-item-title>
            <span v-if="item.type == 'DOCUMENT'">
              {{
                item.object.shortType +
                ' ' +
                item.name +
                ' / ' +
                getShortGermanDate(item.object.date) +
                ' (' +
                item.object.state +
                ')'
              }}
            </span>

            <span v-else-if="item.type == 'ADDRESS'">
              {{
                item.name +
                ', ' +
                item.object.kdStrasse +
                ', ' +
                item.object.kdPlz +
                ' ' +
                item.object.kdOrt
              }}
            </span>

            <span v-else-if="item.type == 'ARTICLE'">
              {{ item.name + ' (' + item.object.art_nr + ')' }}
            </span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon>mdi-arrow-right</v-icon>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'
import { getShortGermanDate } from '@/services/calendar/dateTimeService.js'
import { getSearchResults } from '@/services/core/searchService.js'

export default {
  data() {
    return {
      customerFullTextSearchUrl: '/v1/dashboard/search',
      searchInputSync: '',
      searchOutputSearchLoading: false,
      searchOutputs: [],
      controller: undefined,
    }
  },
  computed: {
    ...mapState({
      document: (state) => state.businessDocument.document,
    }),
  },
  watch: {
    async searchInputSync(newValue) {
      if (newValue === '') {
        this.searchOutputs = []
      }
      await this.searchTrigger()
    },
  },
  methods: {
    getCorrectNoDataText() {
      if (this.searchInputSync == null || this.searchInputSync.length === 0) {
        return 'Suchbegriff eingeben, um die Suche zu starten.'
      }
      if (this.searchInputSync.length > 0 && this.searchInputSync.length < 3) {
        return 'Geben Sie mindestens 3 Zeichen ein, um die Suche zu starten.'
      }
      if (this.searchOutputSearchLoading) {
        return 'Ergebnisse werden geladen...'
      } else {
        return 'Keine Einträge gefunden.'
      }
    },
    initiateSearchUI() {
      this.searchOutputSearchLoading = true
      this.searchOutputs = []
    },
    async searchFullText(query) {
      if (query == undefined) return

      this.initiateSearchUI()

      if (typeof this.controller != typeof undefined) {
        this.controller.abort('Operation canceled due to new request.')
      }

      this.controller = new AbortController()

      const searchResults = await getSearchResults(
        this.customerFullTextSearchUrl,
        query,
        this.controller
      ).catch((err) => {
        console.error('error searching address: ' + err)
      })

      this.searchOutputs = searchResults
      this.searchOutputSearchLoading = false
    },
    async searchTrigger() {
      if (
        this.searchInputSync != null &&
        this.searchInputSync != '' &&
        String(this.searchInputSync).length > 2
      ) {
        this.searchStartedOnce = true
        await this.searchFullText(this.searchInputSync)
      }
    },
    openSearchItem(item) {
      switch (item.type) {
        case 'ADDRESS':
          this.$router.push({ name: 'crmAddress', params: { lfdnr: item.id } })

          break
        case 'DOCUMENT':
          this.$router.push({
            name: 'crmAddressDocumentDetailed',
            params: {
              lfdnr: item.object.addressLfdnr,
              wf_cid: item.object.wf_cid,
            },
          })
          break
        case 'ARTICLE':
          this.$router.push({
            name: 'Artikeldetails',
            params: { articleNumber: item.id },
          })
          break
        default:
          alert('Dieses Feature muss noch implementiert werden.')
      }
    },
    getShortGermanDate,
  },
}
</script>
