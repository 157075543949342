import {
  getAddressInteractions,
  getAddressSubjects,
  AddAddressInteraction,
  editAddressInteraction,
  deleteAddressInteraction,
} from '@/services/crm/addressService.js'
import {
  getDocumentInteractions,
  getDocumentSubjects,
  AddDocumentInteraction,
  editDocumentInteraction,
  deleteDocumentInteraction,
} from '@/services/crm/documentService.js'

export default {
  state: {
    interactions: [],
    availableSubjects: [],
  },
  mutations: {
    SET_INTERACTIONS(state, interactions) {
      state.interactions = interactions
    },
    SET_AVAILABLE_SUBJECTS(state, availableSubjects) {
      state.availableSubjects = availableSubjects
    },
  },
  actions: {
    async loadInteractions({ commit }, { lfdnr, wf_cid }) {
      if (wf_cid == null || wf_cid == '') {
        commit('SET_INTERACTIONS', await getAddressInteractions(lfdnr))
      } else {
        commit('SET_INTERACTIONS', await getDocumentInteractions(wf_cid))
      }
    },

    async loadAvailableSubjects({ commit }, { wf_cid }) {
      if (wf_cid == null || wf_cid == '') {
        commit('SET_AVAILABLE_SUBJECTS', await getAddressSubjects())
      } else {
        commit('SET_AVAILABLE_SUBJECTS', await getDocumentSubjects(wf_cid))
      }
    },
    addInteraction({ dispatch }, { lfdnr, wf_cid, interaction }) {
      if (interaction.wf_cid == null || interaction.wf_cid == '') {
        AddAddressInteraction(lfdnr, interaction).then(() => {
          dispatch('loadInteractions', { lfdnr, wf_cid })
        })
      } else {
        AddDocumentInteraction(interaction).then(() => {
          dispatch('loadInteractions', { lfdnr, wf_cid })
        })
      }
    },
    editInteraction({ dispatch }, { lfdnr, wf_cid, interaction }) {
      if (interaction.wf_cid == null || interaction.wf_cid == '') {
        editAddressInteraction(lfdnr, interaction).then(() => {
          dispatch('loadInteractions', { lfdnr, wf_cid })
        })
      } else {
        editDocumentInteraction(interaction).then(() => {
          dispatch('loadInteractions', { lfdnr, wf_cid })
        })
      }
    },
    deleteInteraction({ dispatch }, { lfdnr, wf_cid, interaction }) {
      if (wf_cid == null || wf_cid == '') {
        deleteAddressInteraction(lfdnr, interaction).then(() => {
          dispatch('loadInteractions', { lfdnr, wf_cid })
        })
      } else {
        deleteDocumentInteraction(wf_cid, interaction).then(() => {
          dispatch('loadInteractions', { lfdnr, wf_cid })
        })
      }
    },
  },
}
