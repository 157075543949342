import {
  formatNumber,
  formatDate,
} from '@/services/formatters/articleFormatService.js'
import {
  getArticle,
  getArticleImages,
  getArticleCheckboxes,
  getArticleInventory,
  getArticleCharacteristics,
  getArticleSalePrices,
  getArticlePurchasePrices,
  getArticleTransport,
  getArticleCommissionedOrdered,
  getArticleStock,
  getOpenArticleOffers,
} from '@/services/article/detailService.js'

export default {
  state: {
    baseInformation: {},
    articleImages: {},
    checkboxes: {},
    characteristics: [],
    characteristicsLoader: true,
    salePrices: [],
    salePricesLoader: true,
    purchasePrices: [],
    purchasePricesLoader: true,
    transport: [],
    transportLoader: true,
    commissionedOrdered: [],
    commissionedOrderedLoader: true,
    stock: [],
    stockLoader: true,
    InventoryManagement: {},
    articleExists: true,
    openOffers: [],
    openOffersLoader: true,
  },
  mutations: {
    // DateFilter
    SET_DATEFILTER_TRANSPORT(state, payload) {
      state.transport[payload.i].show = payload.boolean
      state.transport = [...state.transport]
    },
    SET_DATEFILTER_ORDERED(state, payload) {
      state.commissionedOrdered[payload.i].show = payload.boolean
      state.commissionedOrdered = [...state.commissionedOrdered]
    },

    // If new Article loaded delete old store
    SET_ZERO(state) {
      state.baseInformation = {}
      state.articleImages = {}
      state.checkboxes = {}
      state.characteristics = []
      state.characteristicsLoader = true
      state.salePrices = []
      state.salePricesLoader = true
      state.purchasePrices = []
      state.purchasePricesLoader = true
      state.transport = []
      state.transportLoader = true
      state.commissionedOrdered = []
      state.commissionedOrderedLoader = true
      state.stock = []
      state.stockLoader = true
      state.InventoryManagement = {}
    },

    // Base Information GET
    SET_BASE_INFORMATION(state, newBaseInformation) {
      state.baseInformation = newBaseInformation
    },

    SET_ARTICLE_IMAGES(state, newArticleImages) {
      state.articleImages = newArticleImages
    },

    SET_CHECKBOXES(state, newCheckboxes) {
      state.checkboxes = newCheckboxes
    },

    SET_INVENTORYMANAGEMENT(state, newInventory) {
      state.InventoryManagement = newInventory
    },

    // Data Tables SET
    SET_CHARACTERISTICS(state, newCharacteristics) {
      state.characteristics = newCharacteristics
      state.characteristicsLoader = false
      for (let i = 0, length = state.characteristics.length; i < length; i++) {
        state.characteristics[i].vpe = formatNumber(
          state.characteristics[i].vpe,
          3
        )
        state.characteristics[i].minStock = formatNumber(
          state.characteristics[i].minStock,
          3
        )
        state.characteristics[i].dep = formatNumber(
          state.characteristics[i].dep,
          3
        )
        state.characteristics[i].dbzk = formatNumber(
          state.characteristics[i].dbzk,
          3
        )
      }
    },
    SET_SALEPRICES(state, newSalePrices) {
      state.salePrices = newSalePrices
      state.salePricesLoader = false
      for (let i = 0, length = state.salePrices.length; i < length; i++) {
        state.salePrices[i].validity = formatDate(state.salePrices[i].validity)
        state.salePrices[i].factor = formatNumber(state.salePrices[i].factor, 5)
        state.salePrices[i].netPrice = formatNumber(
          state.salePrices[i].netPrice,
          3
        )
        state.salePrices[i].grossPrice = formatNumber(
          state.salePrices[i].grossPrice,
          3
        )
        state.salePrices[i].db1 = formatNumber(state.salePrices[i].db1, 3)
        state.salePrices[i].ra = formatNumber(state.salePrices[i].ra, 3)
      }
    },
    SET_PURCHASEPRICES(state, newPurchasePrices) {
      state.purchasePrices = newPurchasePrices
      state.purchasePricesLoader = false
      for (let i = 0, length = state.purchasePrices.length; i < length; i++) {
        state.purchasePrices[i].validity = formatDate(
          state.purchasePrices[i].validity
        )
        state.purchasePrices[i].factor = formatNumber(
          state.purchasePrices[i].factor,
          5
        )
        state.purchasePrices[i].listPrice = formatNumber(
          state.purchasePrices[i].listPrice,
          3
        )
        state.purchasePrices[i].netPrice = formatNumber(
          state.purchasePrices[i].netPrice,
          3
        )
        state.purchasePrices[i].purchasePrice = formatNumber(
          state.purchasePrices[i].purchasePrice,
          3
        )
        state.purchasePrices[i].discount = formatNumber(
          state.purchasePrices[i].discount,
          3
        )
        state.purchasePrices[i].discount2 = formatNumber(
          state.purchasePrices[i].discount2,
          3
        )
      }
    },
    SET_TRANSPORT(state, newTransport) {
      newTransport.sort(function (a, b) {
        let dateA = new Date(a.date),
          dateB = new Date(b.date)
        return dateB - dateA
      })
      state.transport = newTransport
      state.transportLoader = false
      for (let i = 0, length = state.transport.length; i < length; i++) {
        state.transport[i].show = true
        state.transport[i].date = formatDate(state.transport[i].date)
        state.transport[i].lDate = formatDate(state.transport[i].lDate)
        state.transport[i].netPrice = formatNumber(
          state.transport[i].netPrice,
          3
        )
        state.transport[i].grossPrice = formatNumber(
          state.transport[i].grossPrice,
          3
        )
        state.transport[i].ownContribution = formatNumber(
          state.transport[i].ownContribution,
          3
        )
      }
    },
    SET_COMMISSIONED_ORDERED(state, newCommissionedOrdered) {
      state.commissionedOrdered = newCommissionedOrdered
      state.commissionedOrderedLoader = false
      for (
        let i = 0, length = state.commissionedOrdered.length;
        i < length;
        i++
      ) {
        state.commissionedOrdered[i].show = true
        state.commissionedOrdered[i].date = formatDate(
          state.commissionedOrdered[i].date
        )
        state.commissionedOrdered[i].netPrice = formatNumber(
          state.commissionedOrdered[i].netPrice,
          3
        )
        state.commissionedOrdered[i].grossPrice = formatNumber(
          state.commissionedOrdered[i].grossPrice,
          3
        )
        state.commissionedOrdered[i].ownContribution = formatNumber(
          state.commissionedOrdered[i].ownContribution,
          3
        )
      }
    },
    SET_STOCK(state, newStock) {
      state.stock = newStock
      state.stockLoader = false
      for (let i = 0, length = state.stock.length; i < length; i++) {
        state.stock[i].inventory = formatNumber(state.stock[i].inventory, 2)
        state.stock[i].reserved = formatNumber(state.stock[i].reserved, 2)
        state.stock[i].ordered = formatNumber(state.stock[i].ordered, 2)
      }
    },
    SET_ARTICLE_EXISTS(state, articleExists) {
      state.articleExists = articleExists
    },
    SET_OPEN_OFFERS(state, newOpenOffers) {
      state.openOffers = newOpenOffers
      state.openOffersLoader = false
      for (let i = 0, length = state.openOffers.length; i < length; i++) {
        state.openOffers[i].show = true
        state.openOffers[i].date = formatDate(state.openOffers[i].date)
        state.openOffers[i].netPrice = formatNumber(
          state.openOffers[i].netPrice,
          3
        )
        state.openOffers[i].grossPrice = formatNumber(
          state.openOffers[i].grossPrice,
          3
        )
        state.openOffers[i].ownContribution = formatNumber(
          state.openOffers[i].ownContribution,
          3
        )
      }
    },
  },
  actions: {
    // Datumsfilter
    SET_DATEFILTER_TRANSPORT({ commit }, payload) {
      commit('SET_DATEFILTER_TRANSPORT', payload)
    },
    // Base Information GET
    async GET_BASE_INFORMATION({ commit }, articleID) {
      try {
        const article = await getArticle(articleID)
        commit('SET_BASE_INFORMATION', article)
        commit('SET_ARTICLE_EXISTS', true)
      } catch (err) {
        commit('SET_ARTICLE_EXISTS', false)
        throw err
      }
    },
    async GET_ARTICLE_IMAGES({ commit }, articleID) {
      commit('SET_ARTICLE_IMAGES', await getArticleImages(articleID))
    },
    async GET_CHECKBOXES({ commit }, articleID) {
      commit('SET_CHECKBOXES', await getArticleCheckboxes(articleID))
    },
    async GET_INVENTORYMANAGEMENT({ commit }, articleID) {
      commit('SET_INVENTORYMANAGEMENT', await getArticleInventory(articleID))
    },
    // Data Tables GET
    async GET_CHARACTERISTICS({ commit }, articleID) {
      commit('SET_CHARACTERISTICS', await getArticleCharacteristics(articleID))
    },
    async GET_SALEPRICES({ commit }, articleID) {
      commit('SET_SALEPRICES', await getArticleSalePrices(articleID))
    },
    async GET_PURCHASEPRICES({ commit }, articleID) {
      commit('SET_PURCHASEPRICES', await getArticlePurchasePrices(articleID))
    },
    async GET_TRANSPORT({ commit }, articleID) {
      commit('SET_TRANSPORT', await getArticleTransport(articleID))
    },
    async GET_COMMISSIONED_ORDERED({ commit }, articleID) {
      commit(
        'SET_COMMISSIONED_ORDERED',
        await getArticleCommissionedOrdered(articleID)
      )
    },
    async GET_STOCK({ commit }, articleID) {
      commit('SET_STOCK', await getArticleStock(articleID))
    },
    async GET_OPEN_OFFERS({ commit }, articleID) {
      commit('SET_OPEN_OFFERS', await getOpenArticleOffers(articleID))
    },
  },
  getters: {
    getOpenOffers(state) {
      return state.openOffers
    },
  },
}
