import axios from 'axios'

export default {
  state: {
    document: {
      documentHead: {
        customer: {},
        association: {},
        doctor: {},
        documentType: {},
        prescriptionState: {},
      },
      documentFoot: {},
      documentHeadExtended: {
        deliveryAddress: {},
        invoiceAddress: {},
        insuredData: {},
      },
      positions: [],
      typeId: 0,
    },
  },
  mutations: {
    SET_DOCUMENT(state, newDocument) {
      state.document = newDocument
    },
  },
  actions: {
    setDocument({ commit }, newDocument) {
      commit('SET_DOCUMENT', newDocument)
    },
    async getDefaultDocument({ commit }, { lfdnr, documentTypeId }) {
      await axios
        .get(`/v1/business/document/default/${lfdnr}`, {
          params: {
            documentTypeId: documentTypeId,
          },
        })
        .then((response) => {
          commit('SET_DOCUMENT', response.data)
        })
    },
    async getDocument({ commit }, wfcid) {
      await axios.get(`/v1/business/document/${wfcid}`).then((response) => {
        commit('SET_DOCUMENT', response.data)
      })
    },
  },
}
